export type ModalObject = {
  open: boolean;
  newlyCreatedUserEmail: string;
  createdUserEmailStatus: Object;
  showAdminModal: boolean;
};

const modal: ModalObject = {
  open: false,
  newlyCreatedUserEmail: "",
  createdUserEmailStatus: {},
  showAdminModal: false,
};

export default modal;
