import { useCallback, useEffect } from "react";

import { useRouter as useNextRouter } from "next/router";

import { captureException } from "@sentry/minimal";
import { captureMessage } from "@sentry/nextjs";

const useUTMSource = () => {
  const nextRouter = useNextRouter();

  const handleUTMSource = useCallback(() => {
    try {
      const queries = Object.keys(nextRouter?.query);

      if (queries?.length > 1) {
        const utms = {
          utm_term: nextRouter?.query?.utm_term,
          utm_source: nextRouter?.query?.utm_source,
          utm_medium: nextRouter?.query?.utm_medium,
          utm_campaign: nextRouter?.query?.utm_campaign,
          utm_content: nextRouter?.query?.utm_content,
          src: nextRouter?.query?.src,
        };

        const utmsValues = Object.values(utms);
        const hasAtLeastOneUtm = utmsValues.some((utm) => utm);

        if (hasAtLeastOneUtm) {
          sessionStorage?.setItem("utms", JSON.stringify(utms));
        }
      }
    } catch (error) {
      const errorMessage =
        "[_app]: an error occurred in the useEffect that checks UTMs.";

      console.log(errorMessage, error);
      captureException(error);
      captureMessage(errorMessage);
    }
  }, [nextRouter?.query]);

  useEffect(() => {
    handleUTMSource();
  }, [handleUTMSource]);

  return {};
};

export default useUTMSource;
